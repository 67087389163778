<template>
	<div class="main">
		<div class="header">

			<div class="box">
				<div class="info">
					<img src="../assets/imgs/face@2x.png" />
					<div class="account">
						<li>{{ info.name }}</li>
						<li>UID:{{ info.code }}</li>
					</div>
					<div class="account_info">

						<li class="lv">LV0</li>


					</div>
					<!--<div class="verif">
						<div :class="{ s: kyc == true, n: kyc == false }">{{ kyc_txt
						}}</div>
					</div>
					 -->

				</div>



			</div>
			<div class="flat-box">
				<div class="warp">
					<div class="detail">
						<div class="item">
							<li>{{ balance }}</li>
							<li>{{ $t('balance') }}</li>

						</div>

						<div class="item">
							<li>{{ salary }}</li>
							<li>{{ $t('sarlry') }}</li>

						</div>
					</div>
					<div class="recharge">
						<router-link to="/deposit">
							<div>{{ $t('deposit') }}</div>
						</router-link>
						<router-link to="/payout">
							<div class="w">{{ $t('payout') }}</div>
						</router-link>

					</div>
				</div>
			</div>

		</div>

		<div class="invite">
			<h1>Earn at least 10% bonus</h1>
			Invite friends to join get bonus not limited
		</div>
		<div class="actions">
			
			<div class="item" @click="lang = true">

				{{ $t('lang') }}
				<i>--</i>
			</div>
			<router-link to="/share">
				<div class="item">

					Share
					<i>Account Settlement</i>
				</div>
			</router-link>
			<router-link to="/update_bank">
				<div class="item">

					Update withdrawal bank
					<i>Account Settlement</i>
				</div>
			</router-link>

			<router-link to="/settlement">
				<div class="item">

					{{ $t('fundlog') }}
					<i>Account Settlement</i>
				</div>
			</router-link>
			<router-link to="/salary">
				<div class="item">

					{{ $t('sarlry_log') }}
					<i>Payroll Records</i>
				</div>
			</router-link>
			<router-link to="/hist">
				<div class="item">

					{{ $t('fund_log') }}
					<i>Account Settlement</i>
				</div>
			</router-link>
			<router-link to="/reward">
				<div class="item">

					{{ $t('invite_log') }}
					<i>
						Protect account security</i>
				</div>
			</router-link>

			<router-link to="/recods">
				<div class="item">

					{{ $t('hist') }}
					<i>Transaction History</i>
				</div>
			</router-link>
			<!--

			<router-link to="/kyc">
				<div class="item">

					{{ $t('idvef') }}
					<i>Your Verification Status</i>
				</div>
			</router-link>
			-->

			<router-link to="/safe">
				<div class="item">

					{{ $t('acc_safe') }}
					<i>
						Protect account security</i>
				</div>
			</router-link>



			<!--

			<router-link to="/recods">
				<div class="item">

					Share Bit Quant
					<i>
						Protect account security</i>
				</div>
			</router-link>
			-->
			<div @click="logout" class="signout">{{ $t('sigin_out') }}</div>




		</div>
		<Tabbar :now="'4'" />
		<van-popup class="td" :overlay="true" v-model="lang" position="bottom" :style="{ height: '50%', width: '100%' }">
			<div class="lists">
				<li @click="change_lang(item.code)" v-for="(item, index) in lang_list" :key="index">{{ item.title }}</li>
				<base-btn>Confirm</base-btn>
			</div>



		</van-popup>
	</div>
</template>

<script>
import Tabbar from "../components/tabbar.vue";
import Clipboard from "clipboard";
import TopHeader from "./header.vue";
import { Dialog } from "vant";
import AnimatedNumber from "animated-number-vue";
export default {
	name: "Profile",
	components: {
		Tabbar,
		AnimatedNumber,
		TopHeader
	},
	created() {
		this.getInfo();
	},
	data() {
		return {
			lang_list: [{ title: "English", code: "en" }, { title: "Hindi", code: "hd" }],
			lang: false,
			total: 0.0,
			balance: 0.0,
			salary: 0.0,


			info: "",
			face: "",
			url: "",
			kyc: false,
			kyc_txt: "Not Verified"
		};
	},
	activated() {
		this.getInfo();
		let _this = this;
	},
	created() {
		this.kyc_txt = this.$t('notverfd')
		this.getShare();
	},

	methods: {
		change_lang(code) {
			localStorage.setItem("lang", code)
			this.i18n.locale = code
			top.location.reload();

		},
		logout() {

			localStorage.clear();


			this.$router.push("/login");
		},
		getShare() {
			this.get("sys/config", { key: "service" }).then(res => {
				this.url = res.data.service;
			});
		},


		getInfo() {
			this.get("user/info").then(res => {
				this.info = res.data;
				this.balance = this.formatToPrice(res.data.balance);
				this.salary = this.formatToPrice(res.data.salary);
				if (res.data.kyc_status == 2) {
					this.kyc_txt = this.$t('verfed')
					this.kyc = true;
				}




			});
		},
		formatToPrice(value) {
			if (this.eyes === false) {
				return "₹******";
			}
			return `₹${Number(value).toFixed(2)}`;
		},


	}
};
</script>

<style  lang="scss" scoped>
.lists {
	padding: 2rem;

	li {
		display: block;
		padding: 1.5rem 2rem;
		text-align: center;
		list-style: none;
		border-bottom: 1px solid #e9e8e7;

	}
}

.main {
	padding-bottom: 10rem;
	padding-top: 27rem;
	background: #fff;

	.signout {
		text-align: center;
		margin: 1rem;
		padding: 2rem;
		background: #dfe0e1;
		color: #111729;
		border-radius: 1rem;
	}

	.invite {
		margin: 1rem;
		padding: 0.5rem 2rem;
		padding-right: 10rem;
		color: #6b7589;
		border-radius: 1rem;
		//border: 1px solid #643beb;

		background: #fff url("../assets/bit/invite.png") no-repeat 30rem bottom;
		line-height: 120%;

		h1 {
			color: #663104;
			font-size: 2rem;
		}
	}

	.header {
		background: #fff;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		display: block;

		background-size: cover;

		padding: 0 0rem;

		h1 {
			font-weight: normal;
			color: #fff;
			text-align: center;

			margin: 0;
			padding-top: 1rem;
			font-size: 2.5rem;
		}

		.flat-box {

			height: 15rem;
			position: relative;

			.warp {
				box-shadow: 0px 1px 2px rgb(192, 188, 188);
				top: -3rem;
				border-radius: 1rem 1rem 1rem 1rem;
				background: #fff;
				position: absolute;
				z-index: 10000;
				left: 2vw;
				width: 96vw;

				.recharge {
					padding: 2rem;
					display: flex;
					flex-wrap: nowrap;
					justify-content: space-evenly;

					div {
						background: #663104;
						padding: 1rem 2rem;
						color: #fff;
						border-radius: 2rem;

						&.w {
							background: #fff;
							color: #663104;
							border: 1px solid #663104;

						}

					}

				}

				.detail {
					padding-top: 2rem;
					display: flex;
					flex-wrap: wrap;

					justify-content: space-evenly;

					li {
						padding: 0.5rem 0;
						text-align: center;
						display: block;
						list-style: none;
						color: #0d122f;

						&:first-child {
							font-weight: 600;
							font-size: 1.5rem;
						}

						&:last-child {
							font-size: 1rem;
							color: #8d959f;
						}
					}
				}
			}

		}

		.box {

			background: #0c1231;



			.account_info {

				padding: 3rem 2rem;
				display: flex;
				flex-wrap: nowrap;

				justify-content: stretch;

				li {
					vertical-align: middle;
					color: #fff;
					list-style: none;
					padding: 1rem;

					&.lv {
						//box-shadow: 3px 3px 5px rgb(192, 188, 188);
						color: #aa8ff5;
						border: 1px solid #aa8ff5;
						padding: 0.5rem 1rem;
						border-radius: 1rem;
					}
				}
			}





			.info {

				display: flex;
				flex-wrap: nowrap;

				justify-content: stretch;


				padding: 2.6rem 0rem;
				padding-bottom: 1rem;

				img {
					margin-left: 2rem;


					width: 6.25rem;
					height: 6.25rem;

				}

				.verif {

					width: 10rem;
					position: relative;

					div {
						//box-shadow: 10px 5px 10px rgb(192, 188, 188);
						width: 50rem;
						position: absolute;
						left: 3rem;
						top: 1rem;
						font-size: 1rem;

						&.s {
							background: #28baa6;
						}

						&.n {
							background: #ed3507;
						}


						color: #fff;
						border-radius: 5rem;
						padding: 1rem 1rem;
						font-weight: bold;
						padding-left: 3rem;
					}
				}

				.account {


					list-style: none;

					margin-left: 2rem;

					li {
						text-align: left;
						padding: 0.5rem 0;
						display: block;
						color: #fff;

						&:last-child {
							color: #aa8ff5;
							opacity: 0.9;
						}
					}

					li:first-child {

						font-weight: bold;
					}

				}


			}

		}
	}

	.actions {

		margin: 0 0rem;
		border-radius: 1rem;
		margin: 1rem;
		//border: 1px solid #e3e8ef;
		background: #fff;

		.item {

			display: block;
			padding: 1.5rem 3rem;
			color: #56585d;
			font-size: 2rem;
			//border: 1px solid #e3e8ef;
			margin: 0 1rem;
			//border-radius: 1rem;
			padding: 2rem 2rem;

			i {

				display: none;
				font-style: normal;
				color: #677389;

				padding: 0.2rem 0;

			}

			background:#fff url("../assets/bit/icon/chevron-right.png") no-repeat 37rem center;
			background-size: 2rem;


		}
	}
}
</style>
