<template>
	<div class="home has-tabbar">
		<Header title="GBY Lever" />
		<div class="asset">
			<h1>{{ $t('total_asstes') }}</h1>
			<div class="balance">{{ formatToPrice(info.balance) }}</div>

			<div class="icon-group">

				<router-link to="/deposit">
					<div class="depoist">
						<img src="../assets/bit/recharge.png" />
						<li>{{ $t('deposit') }}</li>
					</div>
				</router-link>

				<router-link to="/payout">
					<div class="withdraw">
						<img src="../assets/bit/box.png" />
						<li>{{ $t('payout') }}</li>
					</div>
				</router-link>
				<router-link to="/direct">
					<div class="invite">
						<img src="../assets/bit/team.png" />
						<li>{{ $t('team') }}</li>
					</div>
				</router-link>

				<router-link :to="{
				path: '/channel'
			}">
					<div class=" service">
						<img src="../assets/bit/channel.png" />
						<li>Channel</li>
					</div>
				</router-link>
				<router-link :to="{
				path: '/service'
			}">
					<div class=" service">
						<img src="../assets/bit/service.png" />
						<li>Service</li>
					</div>
				</router-link>

			</div>

		</div>
		<div class="notice">
			<router-link :to="{
				path: '/announcement', query: {
					id: notice.id
				}
			}">

				<div class="title">{{ notice.title }}</div>

				<div class="time">{{ notice.created_at }}</div>
			</router-link>
		</div>
		<!--

	 	<div class="signal">
			<div class="title">Signal Channel</div>
			<div class="warp">
				<div>

					<i class="h">23 Hour</i>
					<i class="m">20 minute</i>
					<i class="s">03 Second</i>

				</div>
				<div><img src="../assets/bit/tg.png"></div>
				<div><img src="../assets/bit/wa.png"></div>
			</div>
		</div>
		 -->
		<div class="top">

			<div class="content">


				<div v-for="(item, index, i) in product_list" v-if="i > 2" :key="i" class="item">
					<router-link
						:to="{ path: '/kline', query: { code: item.code, name: item.title, title: item.name } }">
						<img :class="{
				shake: item.zdf > 0
			}" :src="host + '/static/icon/' + item.title + '.png'" />
						<div class="info">
							<li>{{ item.name }}</li>
							<li>{{ item.title }}</li>
						</div>
						<div class="data">
							<i>{{ item.price }}</i>
							<i :class="{ shake: item.zdf > 0, call: item.zdf >= 0, put: item.zdf < 0 }">{{ item.zdf
								}}%</i>
						</div>
					</router-link>
				</div>

			</div>
		</div>

		<div class="ad">
			<h1>Earn at least 15%</h1>
			Invite friends to join get bonus
		</div>




		<!--<div class="news">
			<div class="nav">
				<li class="ac">News</li>
				<li>New user guide</li>

			</div>
			<div class="item">
				<div class="img"><img src="../assets/bit/new2.png"></div>
				<div class="info">
					<div class="title">Getting Rich Off Crypto in 2023 - Best Methods Exposed</div>
					<div class="time">2030-23-33 12</div>
				</div>

			</div>
			<div class="item">
				<div class="img"><img src="../assets/bit/new2.png"></div>
				<div class="info">
					<div class="title">BTC cleared stops above the strong horizontal resistance but couldn't close the
						candle above it.</div>
					<div class="time">2030-23-33 12</div>
				</div>

			</div>
			<div class="item">
				<div class="img"><img src="../assets/bit/new2.png"></div>
				<div class="info">
					<div class="title">Binance Options Will Launch BTCUSDT and ETHUSDT Quarterly Options</div>
					<div class="time">2030-23-33 12</div>
				</div>

			</div>

		</div>-->




		<Tabbar :now="'1'" />
		<van-popup closeable v-model="showPop2" class="notice2">

			<div class="content" v-html="msgs">

			</div>
		</van-popup>
	</div>
</template>

<script>
import Tabbar from "../components/tabbar.vue";
import Header from "./header.vue";

export default {
	name: "Home",
	components: {
		Tabbar,
		Header,

	},
	data() {
		return {
			msgs: [],
			showPop2: false,
			shak: false,
			info: "",
			code: "",
			notice: "",


			product_list: {},
		};
	},

	created() {
		//this.i18n.locale = "hd"
		this.userinfo();
		this.getnotice();

		this.getProductList();
		this.$nextTick(() => {
			//new BScroll('.top')
		})

		//const bs = new BScroll('.div')


	},
	activated() {
		this.userinfo();
		this.getnotice();
		this.ws.conn();
		this.ws.Sub(this.marketData, "home");
		this.getMsg();
	},
	methods: {
		getMsg() {
			this.get("channel/list", { tp: 2 }).then((res) => {
				this.msgs = res.data[0].msg;
				this.showPop2 = true
			});
		},
		creat_acc(data) {
			this.post_chat("create", { plat: this.plat, nick_name: data.name + '-' + data.code, vip: 0 }).the(res => {

			})
		},
		getnotice() {
			this.get("notice/last").then(res => {
				this.notice = res.data

			})
		},
		formatToPrice(value) {

			return `₹${Number(value).toFixed(2)}`;
		},
		userinfo() {
			this.get("user/info").then(res => {
				this.info = res.data;
				this.code = res.data.code
				this.creat_acc(res.data)

			})
		},
		marketData(data) {
			let code = data.ch.split(".");
			if (code.length != 3) {
				return;
			}
			//console.log(code);
			this.product_list[code[1]].price = data.tick.close;

			this.product_list[code[1]].price;
			this.product_list[code[1]].zdf = (
				((data.tick.close - data.tick.open) / data.tick.open) *
				100
			).toFixed(2);
			this.product_list[code[1]].zd = (
				(data.tick.close - data.tick.open)

			).toFixed(2);
			//this.k++;


		},
		getProductList() {
			this.get("product/list").then(res => {
				let obj = {};
				res.data.forEach(item => {
					obj[item.code] = item;
					obj[item.code].price = 0.00;
					obj[item.code].vol = 0;
					obj[item.code].zd = 0.00;
				});
				this.product_list = obj;
			});
		},






	},


};
</script>
<style lang="scss">
.notice2 {
	//border: 1px solid #e3e8ef;
	border-radius: 1rem;
	display: block;
	padding: 1rem;


	div {
		padding: 0.3rem 0;

		display: block;


		&.content {

			max-height: 60vh;

			p {
				img {
					max-width: 80vw !important;
				}
			}

			line-height: 140%;


			b {
				font-weight: bold;
				font-size: 2rem;
				color: #03c855;
			}


			padding: 2rem 0.1rem;




			text-overflow: ellipsis;
			display: -webkit-box; //作为弹性伸缩盒子模型显示。
			-webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
			overflow-x: scroll;

			color: #616162;

		}


	}

}

.signal {
	background: #fff;
	border: 1px solid #e4e8ee;
	margin: 1rem;
	padding: 1rem;
	border-radius: 1rem;

	.title {
		position: relative;
		color: #4878fb;
		padding: 1rem 1rem;
		font-size: 2rem;
		border-bottom: 1px solid #e4e8ee;
		margin-bottom: 1rem;
		padding-left: 2rem;


		&::before {
			position: absolute;
			display: block;
			height: 2rem;
			width: 1rem;
			left: 0rem;
			background: #4878fb;
			content: " ";
		}
	}

	.warp {
		display: flex;
		flex-wrap: wrap;
		font-size: 1.2rem;
		justify-content: space-evenly;

		div {
			vertical-align: middle;

			i {

				font-size: 2rem;
				text-align: left;

				&.h {
					color: #e7aeae;
				}

				&.m {
					color: #a906df;
				}

				&.s {
					color: #08cdf5;
				}


				display: block;
				font-style: normal;
			}


			img {

				display: block;
				width: 8rem;
				height: 8rem;
			}
		}
	}
}

.notice {
	//border: 1px solid #e3e8ef;
	border-radius: 1rem;
	margin: 1rem;
	margin-top: 7rem;
	background: #fff url("../assets/bit/icon/announcement.png") no-repeat 1rem 2rem;
	background-size: 3rem;
	display: block;
	padding: 2rem;
	padding-left: 5rem;

	div {
		padding: 0.3rem 0;

		display: block;

		&.title {
			color: #111729;

		}

		&.content {

			height: 4rem;

			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box; //作为弹性伸缩盒子模型显示。
			-webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
			-webkit-line-clamp: 2; //显示的行
			color: #c7c8cd;

		}

		&.time {
			padding-top: 1rem;
			color: #dcdcdd;
		}
	}

}

.news {
	padding: 2rem;

	.nav {
		padding-bottom: 1rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: start;
		border-bottom: 1px solid #e3e8ef;

		li {
			margin-left: 1rem;
			padding: 1rem;

			list-style: none;

			color: #97a2b6;

			&.ac {
				border-bottom: 1px solid #643beb;
				color: #643beb;
			}
		}

	}


	h1 {
		color: #111729;
		font-size: 2rem;

	}

	.item {
		display: flex;
		flex-wrap: wrap;
		justify-content: start;
		border-bottom: 1px solid #e3e8ef;
		padding: 1rem 0;


		.img {
			overflow: hidden;
			width: 30%;
			text-align: center;

			img {


				max-height: 8rem;
				vertical-align: middle;

			}
		}

		.info {
			width: 65%;
			padding-left: 2rem;
		}

		.title {

			color: #111729;


			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box; //作为弹性伸缩盒子模型显示。
			-webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
			-webkit-line-clamp: 2; //显示的行
		}

		.time {

			padding-top: 1rem;
			color: #677389;
		}

	}

}

.shake {
	animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97);
	transform: translate3d(0, 100, 0);
}

@keyframes shake {

	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}

h1 {
	&.title {

		padding-left: 2rem;
		display: block;
		width: 100%;
		color: #111729;
		font-size: 2rem;
	}

}

.top {




	white-space: nowrap;
	overflow: hidden;
	overflow-x: scroll;

	::-webkit-scrollbar {
		display: none;
		/* Chrome Safari */
	}




	.content {

		//padding: 2rem;


		display: inline-block;


		.item {
			background: #fff;


			margin-left: 1rem;
			display: inline-block;
			border: 1px solid #e3e8ef;
			width: 13rem;

			border-radius: 1rem;
			padding: 1rem 2rem;




			img {

				width: 4rem;
				height: 4rem;
				border-radius: 4rem;
				border: 0.1rem solid #97a2b6;
			}

			.info {
				float: right;

				padding-left: 0.5rem;


				li {
					list-style: none;

					&:first-child {
						color: #101729;
					}

					&:last-child {

						font-size: 0.8rem;
						color: #677489;
					}
				}
			}

			.data {
				padding-top: 2rem;
				display: block;

				i {
					&:last-child {
						&.call {
							color: #61cb6a;
						}

						&.put {
							color: #d25a6a;
						}



						text-align: right;
					}

					display: inline-block;
					font-style: normal;
					width: 50%;
				}
			}
		}
	}
}


.ad {

	padding: 1rem;
	padding-right: 8rem;
	background: #fff url("../assets/bit/ad.png") no-repeat 80vw bottom;
	background-size: 8rem;
	border-radius: 1rem;
	border: 1px solid #b19df4;
	margin: 1rem;
	color: #677389;

	h1 {
		margin: 0;
		padding: 0;
		font-size: 3rem;
		color: #111729;
	}
}

.asset {
	position: relative;
	padding: 4rem;
	background: #4878fb;
	padding-bottom: 8rem;

	h1 {
		font-weight: 300;
		color: #97a2b6;
	}

	.balance {
		color: #fff;
		font-size: 3rem;
		font-weight: bold;
	}
}




.home {
	background: #fff;


	min-height: 100vh;



	.icon-group {
		top: 14rem;
		width: 90vw;
		left: 3vw;
		position: absolute;
		display: flex;
		flex-wrap: wrap;

		justify-content: space-between;
		padding: 1rem;
		background: #fff;
		border-radius: 1rem;

		div {
			text-align: center;
			display: inline-block;

			img {

				width: 3.6rem;
				height: 3.6rem;


				margin-top: 2.69rem;
			}

			li {
				list-style: none;
				display: block;
				margin-top: 1rem;
				color: #677389;
				min-width: 2rem;

				//padding-left: 2rem;

			}

			//width: 25%;




		}
	}
}
</style>
